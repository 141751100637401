import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./permission";

import api from "./api";
Vue.prototype.$api = api;

import VueLuckyCanvas from "@lucky-canvas/vue";
Vue.use(VueLuckyCanvas);

import VideoPlayer from "vue-video-player";
import "video.js/dist/video-js.css";

Vue.use(VideoPlayer);

// import "./assets/css/ele.less";
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(Element, { size: "small", zIndex: 3000 });

// 轮播swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
import swipercss from 'swiper/css/swiper.min.css'
Vue.use(VueAwesomeSwiper, {
  swipercss
});


Vue.config.productionTip = false;
// 挂载websocket
import websocket from "@/utils/webSocket";
Vue.prototype.$websocket = websocket;

new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
}).$mount("#app");
